.content {
  height:'100%';
  padding:'1%';
  font-size: 16px;
}

#cover{
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../images/welcome.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.bigicon svg {
  width: 30px;
  height: 30px;
}

.antd-pro-charts-chart-card-chartCard .antd-pro-charts-chart-card-avatar {
  margin-top: 10px;
}